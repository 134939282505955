import React, { use, useState } from "react";
import styled from "styled-components";
import { theme } from "./Theme";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Footer = () => {
	const [isTextCopied, setisTextCopied] = useState(false);
	const handleDownload = () => {
		const link = document.createElement("a");
		link.href = "/Iqra Imran Syed.pdf";
		link.download = "Iqra Imran Syed.pdf";
		link.click();
	};

	const links = [
		{
			url: "https://www.linkedin.com/in/iqraimransyed/",
			icon: "LI",
		},
		{
			url: "https://github.com/iqrasy",
			icon: "GH",
		},
		{
			url: "mailto:syiqra@hotmail.com",
			icon: "EM",
		},
	];

	const handleCopy = () => {
		setisTextCopied(true);
		setTimeout(() => {
			setisTextCopied(false);
		}, 5000);
	};

	return (
		<Div>
			{isTextCopied && <CopyConfirmation>COPIED</CopyConfirmation>}
			<Container>
				<Text>
					<span>DONT BE A STRANGER</span>
					<p>LET'S TALK</p>
				</Text>
				<CopyToClipboard onCopy={handleCopy} text="syed@imraniqra.com">
					<Email>
						<p>syed@imraniqra.com</p>
					</Email>
				</CopyToClipboard>
			</Container>
			<LinkContainer>
				<Links>
					<h3>LINKEDIN</h3>
					<span>.01</span>
				</Links>
				<Links>
					<h3>GITHUB</h3>
					<span>.02</span>
				</Links>
				<Links>
					<h3>RESUME</h3>
					<span>.03</span>
				</Links>
			</LinkContainer>
			<FooterContainer>
				<p>Built and Designed by Iqra</p>
				<p>©2023 </p>
			</FooterContainer>
		</Div>
	);
};

export default Footer;

const Div = styled.div`
	background-color: var(--text-400);
	height: 100vh;
	width: 100vw;
	padding: 20px;
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	position: relative;
	width: 100%;
	color: var(--text-200);
`;

const Text = styled.div`
	font-family: "Bueno-regular", sans-serif;
	font-size: 80px;
	line-height: 1;

	p {
		text-indent: 50.5%;
		margin: 0;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		font-size: 40px;

		p {
			text-indent: 20%;
		}
	}
`;

const Email = styled.div`
	font-size: 20px;
	display: flex;
	align-items: flex-end;
	cursor: pointer;
	justify-content: flex-end;

	p {
		text-decoration: underline;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		font-size: 10px;

		p {
			text-indent: 20%;
		}
	}
`;

const CopyConfirmation = styled.div`
	height: fit-content;
	width: fit-content;
	position: absolute;
	right: 70px;
	background-color: #dad7cd;
	border-radius: 10px;
	padding: 10px;
	color: var(--text-400);
	font-size: 13px;
	white-space: nowrap;
`;

const LinkContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	gap: 2rem;
	height: 50vh;

	span {
		font-family: "Bueno-regular", sans-serif;
	}
`;

const Links = styled.div`
	display: flex;
	align-items: center;
	gap: 2rem;
	border-bottom: solid 1px var(--accent-100);
	color: var(--text-200);
`;

const FooterContainer = styled.footer`
	position: relative;
	bottom: 0;
	top: 240px;
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	color: var(--text-200);
`;
