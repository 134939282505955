import React from "react";
import GlobalStyles from "./Globalstyle";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import styled from "styled-components";
import cloudBackground from "../assets/couds.jpg";

const App = () => {
	return (
		<>
			<BrowserRouter>
				<GlobalStyles />
				{/* <SplineContainer> */}
				{/* <div style={{ width: "100%", maxWidth: "300px", overflow: "hidden" }}>
						<iframe
							title="Spotify Playlist"
							style={{ borderRadius: "12px" }}
							src="https://open.spotify.com/embed/playlist/37i9dQZF1EQqFPe2ux3rbj?utm_source=generator"
							width="100%"
							height="352"
							allowfullscreen=""
							allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
							loading="lazy"
						></iframe>
					</div> */}
				<Routes>
					<Route path="/" element={<Home />} />
				</Routes>
				{/* </SplineContainer> */}
			</BrowserRouter>
		</>
	);
};

export default App;

const SplineContainer = styled.div``;
