import React from "react";
import styled from "styled-components";
import Clock from "react-live-clock";
import Spline from "@splinetool/react-spline";

const Hero = () => {
	const url = "https://prod.spline.design/c-2of458SSJIva06/scene.splinecode";
	return (
		<Content>
			<HeaderContainer>
				<Spline scene={url} />
				<ClockContainer>
					<p>NOUVO STUDIO</p>
					<Clock format={"HH:mm"} ticking={true} timezone={"US/Eastern"} /> -
					{"  "} EST - MONTREAL
				</ClockContainer>
			</HeaderContainer>
			<SubHeader>
				<p>SCROLL TO KNOW MORE</p>
			</SubHeader>
		</Content>
	);
};

export default Hero;

const Content = styled.div``;

const HeaderContainer = styled.div``;

const SubHeader = styled.div`
	color: var(--text-300);
	position: absolute;
	bottom: 5rem;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;

	p {
		font-size: 14px;
	}
`;

const ClockContainer = styled.div`
	color: var(--text-300);
	position: absolute;
	top: 0;
	left: 0;
	margin: 1rem;
	font-size: 14px;
	padding: 0.4rem 1rem;
	height: 1rem;

	p {
		margin-bottom: 0;
	}
`;
