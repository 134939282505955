import React from "react";
import styled from "styled-components";
import About from "./About";
import Hero from "./Hero";
import Projects from "./portfolio/Projects";
import Footer from "./Footer";
import cloudBackground from "../assets/couds.jpg";
import Experience from "./Experience";
import ProjectTwo from "./portfolio/ProjectTwo";

const Home = () => {
	return (
		<Content>
			<Container>
				<HeroContainer>
					<Hero />
				</HeroContainer>
				<AboutMeContainer>
					<About />
				</AboutMeContainer>
			</Container>
			{/* <ProjectContainer>
				<ProjectTwo />
				<Projects />
			</ProjectContainer> */}
			{/* <div style={{ height: "50vh" }}></div> */}
			<ContactContainer>
				<Footer />
			</ContactContainer>
		</Content>
	);
};

export default Home;

const Content = styled.div``;

const Container = styled.div`
	background-image: url(${cloudBackground});
`;

const HeroContainer = styled.section`
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	/* border: solid green 1px; */
`;

const AboutMeContainer = styled.section`
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ContactContainer = styled.section`
	/* height: 100vh; */
`;

const ProjectContainer = styled.div`
	margin-bottom: 100vh;
`;
