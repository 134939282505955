import React from "react";
import styled from "styled-components";
import { theme } from "./Theme";
import { ReactTyped } from "react-typed";
import portrait from "../assets/me.jpg";
import sidebar from "../assets/sidebar-svgrepo-com.svg";
import arrow from "../assets/arrow-down-svgrepo-com.svg";
import info from "../assets/info-circle-svgrepo-com (1).svg";
import zoomOut from "../assets/zoom-minus-svgrepo-com.svg";
import zoomIn from "../assets/zoom-plus-svgrepo-com.svg";
import exportImage from "../assets/export-svgrepo-com.svg";
import doubleArrow from "../assets/double-arrow-right-svgrepo-com.svg";
import search from "../assets/search-svgrepo-com (1).svg";

{
	/* <IconContainer>
							<img src={info} style={{ height: "15px" }} />
							<img src={zoomOut} style={{ height: "18px" }} />
							<img src={zoomIn} style={{ height: "18px" }} />
							<img src={exportImage} style={{ height: "17px" }} />
							<span style={{ padding: "0 12px", color: "light grey" }}>|</span>
							<img src={doubleArrow} style={{ height: "16px" }} />
							<img src={search} style={{ height: "17px", color: "grey" }} />
						</IconContainer> */
}

const About = () => {
	return (
		<Div>
			<AboutContainer>
				<Header>
					<h1>YUP, THAT'S ME</h1>
				</Header>
				<IosImageContainer>
					<NavContainer>
						<CircleContainer>
							<span></span>
							<span></span>
							<span></span>
						</CircleContainer>
						<Title>
							<div className="image-container">
								<SidebarIcon src={sidebar} />
								<ArrowDownIcon src={arrow} />
							</div>
							<p>IMG_2667.HEIC</p>
						</Title>
					</NavContainer>
					<Image src={portrait} alt="a picture of me" />
				</IosImageContainer>
				<InfoContainer>
					<ReactTyped
						loop={false}
						typeSpeed={40}
						startWhenVisible
						strings={[
							"I'm Iqra! A frontend developer based in Montreal with 2+ years of experience. I was always drawn to design and technology. My love for coding started a few years ago, inspired by a friend's website featuring different potato recipes (silly, I know lol). But that led me to understanding how much I love coding. I build intuitive and visually appealing interfaces with React.js and JavaScript, always striving for the perfect balance between functionality and aesthetics. Lately, I've been diving into graphic design, merging my love for code with creative storytelling. When I'm not coding, I love sketching out design ideas, hitting the gym, or experimenting in the kitchen.",
						]}
					/>
				</InfoContainer>
			</AboutContainer>
		</Div>
	);
};

export default About;

const Div = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 2rem;
	width: 100%;
	height: 100vh;
	flex-direction: column;
	position: relative;
`;

const AboutContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: center;
	width: 100%;

	@media (max-width: ${theme.breakpoints.sm}) {
		height: 100vh;
	}
`;

const Header = styled.div`
	color: var(--text-400);
	font-family: "Bueno-regular", sans-serif;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
	position: absolute;
	top: 0;
	right: 0;

	h1 {
		font-size: clamp(3rem, 5vw, 6rem);
		text-align: center;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
	}
`;

const IosImageContainer = styled.div`
	height: 450px;
	max-width: 340px;
	margin: 20px;
	border-radius: 8px;
	position: absolute;
	top: 0;
	left: 22%;
	right: 0;

	@media (max-width: ${theme.breakpoints.sm}) {
		height: 300px;
		width: 245px;
		top: 10%;
		left: 0%;
		right: 0;
	}
`;

const InfoContainer = styled.div`
	max-width: 450px;
	border-radius: 20px;
	padding: 16px;
	color: white;
	z-index: 10;
	background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
	position: absolute;
	right: 25%;
	top: 36%;

	span {
		font-size: 1.1rem;
		line-height: 1.7;
	}

	@media (max-width: ${theme.breakpoints.xl}) {
		right: 20%;
		top: 50%;
		width: 90vw;

		span {
			font-size: 1rem;
		}
	}

	@media (max-width: ${theme.breakpoints.md}) {
		right: 0%;
		top: 50%;
		width: 90vw;

		span {
			font-size: 1rem;
		}
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		right: 5%;
		top: 47%;
		width: 90vw;

		span {
			font-size: 0.9rem;
		}
	}
`;

const NavContainer = styled.div`
	display: flex;
	background-color: #ffffff;
	height: 45px;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;

	@media (max-width: ${theme.breakpoints.sm}) {
		height: 30px;
	}
`;

const CircleContainer = styled.div`
	width: 70px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	span {
		margin: 3px;
		border-radius: 50%;
		width: 11px;
		height: 11px;
		background-color: red;
	}

	span:nth-child(1) {
		background-color: #ff5f57;
	}

	span:nth-child(2) {
		background-color: #ffbd2e;
	}

	span:nth-child(3) {
		background-color: #28c840;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		margin-left: 5px;
		span {
			width: 9px;
			height: 9px;
		}
	}
`;

const Title = styled.div`
	display: flex;
	align-items: center;
	height: 100%;

	.image-container {
		display: flex;
	}

	p {
		margin: 0;
		font-weight: bolder;
		font-size: 15px;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		p {
			font-size: 12px;
			margin-left: 5px;
		}
	}
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 250px;

	img {
		margin: 7px;
	}

	@media (max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}) {
		img {
			display: none;
		}

		span {
			display: none;
		}
	}
`;

const Image = styled.img`
	width: 100%;
	height: 100%;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
`;

const SidebarIcon = styled.img`
	height: 20px;
	margin-right: 5px;

	@media (max-width: ${theme.breakpoints.sm}) {
		display: none;
	}
`;

const ArrowDownIcon = styled.img`
	height: 20px;
	margin-right: 10px;
	@media (max-width: ${theme.breakpoints.sm}) {
		display: none;
	}
`;
