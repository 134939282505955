import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import styled from "styled-components";
import data from "./ProjectData";
import ReactPlayer from "react-player";
import { theme } from "../Theme";

const CardScroll = () => {
	const sectionRef = useRef(null);

	useEffect(() => {
		const horizontalSections = gsap.utils.toArray(".horizontal");
		console.log(horizontalSections);

		horizontalSections.forEach(function (sec) {
			const thisPinWrap = sec.querySelector(".pin-wrap");
			const thisAnimWrap = thisPinWrap.querySelector(".animation-wrap");

			const getToValue = () => -(thisAnimWrap.scrollWidth - window.innerWidth);

			gsap.fromTo(
				thisAnimWrap,
				{
					x: () =>
						thisAnimWrap.classList.contains("to-right") ? 0 : getToValue(),
				},
				{
					x: () =>
						thisAnimWrap.classList.contains("to-right") ? getToValue() : 0,
					ease: "none",
					scrollTrigger: {
						trigger: sec,
						start: "top top",
						end: () => "+=" + (thisAnimWrap.scrollWidth - window.innerWidth),
						pin: thisPinWrap,
						invalidateOnRefresh: true,
						scrub: true,
					},
				}
			);
		});
	}, []);

	return (
		<Section className="horizontal" ref={sectionRef}>
			<div className="pin-wrap">
				<div className="animation-wrap to-right">
					{data.map((projects, index) => (
						<>
							<ProjectCard key={index}>
								<CardBody className="body">
									<CardClr>
										<Span>{projects.num}</Span>
										<Icon>{projects.icon}</Icon>
									</CardClr>
									<Title>
										<p>{projects.title}</p>
									</Title>

									<Duplicate>
										<Span>{projects.num}</Span>
										<Icon>{projects.icon}</Icon>
									</Duplicate>
									<Hover>{/* <p>HOVER TO VIEW</p> */}</Hover>
									<CenterIcon className="icon">{projects.icon}</CenterIcon>
								</CardBody>
								<ProjectContainer className="project">
									<ProjectInfoContainer>
										<DescriptionContainer>
											<p>{projects.description}</p>
										</DescriptionContainer>
										{/* {projects.images && Array.isArray(projects.images) && (
											<ImagesContainer>
												{projects.images.map((image, imgIndex) => (
													<img
														key={imgIndex}
														src={image.url}
														alt={image.altText}
													/>
												))}
											</ImagesContainer>
										)}

										{projects.video && (
											<ReactPlayer
												url={projects.video}
												playing="true"
												loop="true"
												width={"400px"}
											/>
										)} */}
									</ProjectInfoContainer>
								</ProjectContainer>
							</ProjectCard>
						</>
					))}
				</div>
			</div>
		</Section>
	);
};

export default CardScroll;

const Section = styled.section`
	height: 100vh;
	overflow-x: hidden;
	overflow-y: hidden;
	color: var(--bg-100);

	.pin-wrap,
	.animation-wrap {
		display: flex;
		position: relative;
		top: 20px;
	}
`;

const ProjectCard = styled.div`
	margin: 0 1rem;
	padding: 1rem;
	cursor: pointer;
	border-radius: 30px;
	background-color: var(--text-400);
	border: solid black 1px;
	height: 750px;
	position: relative;
	overflow: hidden;

	&:hover .body {
		opacity: 0;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		height: 150px;
		width: 360px;
	}
`;

const Icon = styled.span`
	font-size: 20px;
`;

const Title = styled.div`
	display: flex;
	justify-content: end;
	align-items: bottom;
	grid-area: 1 / 3 / 2 / 4;
	color: var(--bg-100);
`;

const Hover = styled.div`
	display: flex;
	justify-content: center;
	align-items: end;
	grid-area: 3 / 1 / 4 / 2;
	color: var(--bg-200);
`;

const CenterIcon = styled.div`
	grid-area: 2 / 2 / 3 / 3;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 100px;
`;

const CardBody = styled.div`
	height: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	transition: opacity 0.3s linear;
	width: 400px;
	opacity: 1;

	&:hover {
		opacity: 0;
		transition: opacity 0.2s linear;
	}
`;

const CardClr = styled.div`
	grid-area: 1 / 1 / 2 / 2;
	display: flex;
	flex-direction: column;
	font-size: 50px;
`;

const Duplicate = styled.div`
	grid-area: 3 / 3 / 4 / 4;
	width: 100%;
	display: flex;
	flex-direction: column;
	transform: rotate(180deg);
	font-size: 50px;
`;

const ProjectContainer = styled.div`
	transition: opacity 0.3s linear;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	opacity: 1;

	&:hover {
		opacity: 1;
		transition: opacity 0.3s linear;
	}
`;

const Span = styled.span`
	font-size: 30px;
`;

const ProjectInfoContainer = styled.div``;

const DescriptionContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	padding: 5px 10px;
	color: var(--text-100);
	position: absolute;
	top: 10%;
	font-size: 20px;
`;

const ImagesContainer = styled.div`
	position: relative;
	top: 100px;

	img {
		width: 100%;
		height: 270px;
		border-radius: 8px;
		object-fit: cover;
		cursor: pointer;
	}
`;
