import { createGlobalStyle } from "styled-components";
import cloudBackground from "../assets/couds.jpg";

const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: 'Bueno-Regular';
    src: url('/fonts/Bueno-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


:root {
--custom-highlight-color: #eaded2; 
--text-100: #CDB4DB;
--text-200: #FFC8DD;
--text-300: #FD96BC;
--text-400: #383075;
/* --bg-100: #BDE0FE; */
--bg-100: #DFF0FF;
--bg-200: #A2D2FF;
--accent-100: #EFD256;
}

::selection {
  background-color: var(--custom-highlight-color);
  color: #000814; 
 
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100vh;
 max-width: 100vw;
  margin: 0;
 	/* background-image: url(${cloudBackground});
  background-repeat: no-repeat;
  object-fit: cover; */
}


a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
 }
`;

export default GlobalStyles;
