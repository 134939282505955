import React from "react";
import data from "./ProjectData";
import { theme } from "../Theme";
import styled from "styled-components";

const ProjectTwo = () => {
	return (
		<MainContainer>
			{data.map((projects, i) => (
				<ProjectContainer>
					<p>{projects.title}</p>
				</ProjectContainer>
			))}
		</MainContainer>
	);
};

export default ProjectTwo;

const MainContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	align-items: center;
	justify-content: flex-start;
	overflow-x: scroll;
`;

const ProjectContainer = styled.div`
	background-color: var(--bg-200);
	height: 500px;
	width: 300px;
	border-radius: 20px;
	margin: 10px;
	text-align: center;
	padding: 10px;
`;
